<template>
  <div>
    <coupon
      v-if="coupon"
      ref="couponModal"
      :coupon="coupon"
    />
  </div>
</template>

<script>
import couponApi from '@/apis/liff/v2/coupon'
import * as consts from "@/consts";
import { mapGetters } from 'vuex'
import Coupon from "@/components/Page/Liff/Coupon/Coupon.vue";

export default {
  components: { Coupon },
  data() {
    return {
      coupon: null,
    }
  },
  computed: {
    ...mapGetters({
      lineOaId: 'liffGeneral/liffOaId',
    }),
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const redeemCodeBundle = await couponApi.getRedeemCodeBundle(this.$route.params.id)

      if (redeemCodeBundle.usage_type === consts.REDEEM_CODE_BUNDLE_USAGE_TYPES.IMMEDIATE) {
        this.goToCouponPage(redeemCodeBundle.id, true)
        return;
      }

      this.autoApply()
    },
    async autoApply() {
      try {
        let code = await this.doAutoApply()

        console.log('code', code)
        this.$swal.fire({ title: "領取成功", type: "success" })
          .then(() => this.goToCouponPage(code.id))
      } catch (error) {
        console.log("error", error)
        if ([406, 410].includes(error.response.status)) {
          this.$swal.fire({ title: error.response.data.message, type: "error" })
            .then(() => {error.response.status == 406 ? this.goToCouponPage(error.response.data.id) : this.goToLineOa()})
        } else {
          this.$swal.fire({ title: "領取失敗", type: "error" })
            .then(() => this.goToLineOa())
          }
      }
    },
    async doAutoApply() {
      return await couponApi.applyCouponFromRedeemCodeBundle({ id: this.$route.params.id }, {
        push_line_message: true,
        check_take_type_direct: false,
      })
    },
    goToLineOa() {
      window.location.href = `https://line.me/R/ti/p/@${this.lineOaId}`
    },
    goToCouponPage(id, isImmediateUsage = false) {
      this.$router.push({
        name: 'LiffCouponShow',
        params: {id: id},
        query: { 'is-immediate-usage': isImmediateUsage }
      })
    }
  }
}
</script>
